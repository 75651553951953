import type {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';

export const showSpendControlsParameter = 'show-spend-controls' as const;
export const issuingProgramParameter = 'issuing-program' as const;

const issuingCardsListParams = [
  showSpendControlsParameter,
  issuingProgramParameter,
] as const;

type IssuingCardsListParams = (typeof issuingCardsListParams)[number];
export type IssuingCardsListHtmlParams = Partial<
  Record<IssuingCardsListParams, string>
>;

export const IssuingCardsListConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "issuingCardsList" */
      '../../../components/Issuing/IssuingCardsList'
    ),
  enabledKey: 'issuing_cards_list',
  htmlAttributes: {
    [showSpendControlsParameter]: {
      isInternal: false,
    },
    [issuingProgramParameter]: {
      isInternal: false,
    },
  },
  customHtmlElementMethods: {
    setShowSpendControls: {
      type: 'AttributeSettingMethod',
      attribute: showSpendControlsParameter,
      isInternal: false,
      description: 'Controls displaying the spend controls UI.',
    },
    setIssuingProgram: {
      type: 'AttributeSettingMethod',
      attribute: issuingProgramParameter,
      isInternal: false,
      description:
        'Controls which issuing program should be used for the component.',
    },
    setFetchEphemeralKey: {
      type: 'SupplementalFunction',
      objectToSet: 'fetchEphemeralKey',
      isInternal: false,
      description:
        'Sets the function to fetch an ephemeral key for the card to display PCI specific information for the card (like the entirety of the card number).',
    },
  },
  releaseState: 'ga',
  teamOwner: 'baas_growth',
  description: 'Displays the list of cards on the given Connected Account.',
  docSitePath: '/connect/supported-embedded-components/issuing-cards-list',
  syntheticTestInfo: {
    testUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/sus-issuing-list-i-rc',
    rcTestUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/sus-issuing-list-i-rc',
  },
};
