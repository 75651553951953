import type {ConnectElementConfig} from '../ConnectElementList';

export const ProductTaxCodeSelectorConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "productTaxCodeSelector" */
      '../../../components/Tax/ProductTaxCodeSelector'
    ),
  enabledKey: 'product_tax_code_selector', // Flag that will determine if the custom element should be rendered
  releaseState: 'preview',
  teamOwner: 'tax_connect_embedded_components', // Enter your team's name - this is used for SFX reporting and sentry. See docs on how to set up: https://confluence.corp.stripe.com/display/OBS/Setting+up+Sentry+Reporting#SettingupSentryReporting-SettingupaSentryproject
  docSitePath:
    '/connect/supported-embedded-components/product-tax-code-selector',
  customHtmlElementMethods: {
    setOnTaxCodeSelect: {
      type: 'SupplementalFunction',
      objectToSet: 'onTaxCodeSelect',
      isInternal: false,
      description:
        'Sets the function to call when the user selects a product tax code.',
    },
    setHideDescription: {
      type: 'SupplementalObject',
      objectToSet: 'hideDescription',
      isInternal: false,
      description: 'Sets whether the product tax code description is hidden.',
    },
    setDisabled: {
      type: 'SupplementalObject',
      objectToSet: 'disabled',
      isInternal: false,
      description: 'Sets whether the product tax code selector is disabled.',
    },
    setInitialTaxCode: {
      type: 'SupplementalObject',
      objectToSet: 'initialTaxCode',
      isInternal: false,
      description: 'Sets the initial product tax code.',
    },
  },
  description: 'Renders the product tax code selector for the account.',
};
