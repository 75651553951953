export const PERFORMANCE_MONITOR_PARAMS = [
  'id',
  'firstRenderDuration',
  'lastRenderDuration',
  'shortestRenderDuration',
  'longestRenderDuration',
  'averageRenderDuration',
  'renderCount',
  'firstTrackDuration',
  'lastTrackDuration',
  'shortestTrackDuration',
  'longestTrackDuration',
  'averageTrackDuration',
  'trackCount',
  'timeToNetworkDurationExclusive',
  'timeToNetworkDurationInclusive',
] as const;

export type PerformanceMonitorParams = Record<
  (typeof PERFORMANCE_MONITOR_PARAMS)[number],
  unknown
>;
