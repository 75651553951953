import type {
  CustomFontOptions,
  SupportBaseKey,
} from '@stripe-internal/connect-embedded-lib';
import type {AppearanceOptions} from '@stripe-internal/embedded-theming';
import type {Flags} from '@sail/access';
import type {Feature} from '../../data-layer-client/FeaturesContext';
import type {IAccountSessionComponents} from '../../data-layer-client/AuthContext/AuthContext';

/**
 * Represents the app that is hosting connect embedded components. If it is not specified, then it means we are embedding in a platform.
 */
export type HostApp =
  | 'express'
  | 'standard'
  | 'direct_settings'
  | 'platform'
  | 'platform_android'
  | 'platform_ios'
  | 'loginAs'
  | 'docs'
  | 'hostedOnboarding'
  | 'hostedOnboarding_custom'
  | 'hostedOnboarding_standard'
  | 'hostedOnboarding_standardOAuth'
  | 'hostedOnboarding_express'
  | 'hostedOnboarding_expressOAuth'
  | 'hostedOnboarding_durable'
  | 'durableFlow_embeddedTaxDocuments'
  | 'durableFlow_capital';

export const defaultHostApp = 'platform';

export type sdkOptions = {
  sdkVersion?: string;
  reactSdkVersion?: string;
};

export type IFeatureOverrides = Partial<
  Record<keyof IAccountSessionComponents, Partial<Record<Feature, boolean>>>
>;

export type MetaOptions = {
  analyticsRbEventId?: string;
  apiKeyOverride?: string;
  disableAnalytics?: boolean;
  eagerClientSecretPromise?: Promise<string>;
  featureOverrides?: IFeatureOverrides;
  flagOverrides?: Partial<Flags>;
  hostApp?: HostApp;
  isV2Session?: boolean;
  livemodeOverride?: boolean;
  merchantIdOverride?: string;
  mobileSdk?: boolean;
  mobileSdkVersion?: string;
  releaseCandidate?: string;
  restrictToPlatformOwnsOnboarding?: boolean;
  platformIdOverride?: string;
  sdk?: boolean;
  sdkOptions?: sdkOptions;
  supportSiteBaseOverride?: Exclude<SupportBaseKey, 'embedded'>;
  v2WorkspaceIdOverride?: string;
};

export type InitOptions = {
  appearance?: AppearanceOptions;
  clientSecret?: string;
  fetchClientSecret?: () => Promise<string>;
  locale?: string;
  metaOptions?: MetaOptions;
  publishableKey: string;
  refreshClientSecret?: () => Promise<string>;
  fonts?: CustomFontOptions;
};

export type UpdateOptions = {
  appearance?: AppearanceOptions;
  sdkOptions?: sdkOptions;
  locale?: string;
};
